

































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import SearchBar from '@/views/taint/SearchBar.vue'
import SearchCard from '@/views/taint/searchCard.vue'

@Component({
  name: 'TaintIndex',
  components: {
    SearchBar,
    SearchCard,
  },
})
export default class Index extends VueBase {
  private active = false
  private status = false
  private page = 1
  private dataEnd = false
  private type = ''
  private value = ''
  private tableList: Array<any> = []
  private afterkeys = {}
  private loadingFlag = false
  private search([type, value]: any[]) {
    this.type = type
    this.value = value
    this.page = 1
    this.tableList = []
    this.afterkeys = {}
    if (!value) {
      this.$message.warning(this.$t('views.search.warning') as string)
    }

    if (value) {
      this.loadingFlag = true
      this.changeActive()
      this.getList()
    }
  }
  private async getList() {
    if (!this.active) {
      return
    }
    this.dataEnd = true
    const keyArr = [
      'url',
      'signature',
      'sinkvalues',
      'req_header_fs',
      'req_data',
      'res_header',
      'res_body',
    ]
    const searchKey = {}
    if (this.type) {
      searchKey[this.type] = this.value
    } else {
      for (let i = 0; i < keyArr.length; i++) {
        searchKey[keyArr[i]] = this.value
      }
    }
    const exclude_ids = this.tableList.map((item) => {
      return item.method_pools.id
    })
    const SearchBar: any = this.$refs.SearchBar

    let afterObj: any = undefined
    for (let key in this.afterkeys) {
      if (!afterObj) {
        afterObj = {}
      }
      afterObj['search_after_' + key] = this.afterkeys[key]
    }

    const res: any = await this.services.taint.search({
      ...searchKey,
      search_mode: SearchBar.search_mode,
      time_range: [
        ~~(SearchBar.time_range[0].getTime() / 1000),
        ~~(SearchBar.time_range[1].getTime() / 1000),
      ],
      page_index: this.page,
      page_size: 10,
      ...afterObj,
      exclude_ids: exclude_ids,
    })
    this.loadingFlag = false
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    const tableList = res.data.method_pools.map((item: any) => {
      const vulnerablities_count_map = {}
      const relations_map = {}
      res.data.aggregation.vulnerablities_count.forEach((i: any) => {
        vulnerablities_count_map[i.method_pool_id] = i
      })

      res.data.relations.forEach((i: any) => {
        relations_map[i.method_pool_id] = i
      })
      return {
        method_pools: item,
        vulnerablities_count: vulnerablities_count_map[item.id],
        relations: relations_map[item.id],
      }
    })
    this.afterkeys = res.data.afterkeys
    this.tableList = this.tableList.concat(tableList)
    this.dataEnd = false
    if (tableList.length < 10) {
      this.dataEnd = true
    }
  }
  private changeActive() {
    if (this.status) {
    } else {
      this.active = !this.active
      setTimeout(() => {
        this.status = this.active
      }, 400)
    }
  }

  private myScroll() {
    const bottomWindow =
      document.documentElement.scrollTop + window.innerHeight >
      document.documentElement.offsetHeight - 1
    if (bottomWindow) {
      if (!this.dataEnd) {
        this.page += 1
        this.getList()
      }
    }
  }

  mounted() {
    window.addEventListener('scroll', this.myScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.myScroll)
  }
}
